import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import OdometerComponent from '../OdometerComponent';
import { formatCurrencySymbol } from '../../../../utils/formatCurrency';
import { imageBaseUrl } from '../../../../services/http';
import { DEFAULT_CURRENCY } from '../../../../variables';
import getCurrencyByLocation from '@tlf-e/brand-utils/dist/helpers/getCurrencyByLocation/index';

export const JackpotItemInformation = ({ value, name }) => {
    const { currency } = useSelector((state) => state.user.information);
    const { isAuth } = useSelector((state) => state.user);
    const { loc } = useSelector((state) => state.global.clientIp);
    const { currencies } = useSelector((state) => state.global.data);
    const defaultCurrency = isAuth ? currency : getCurrencyByLocation(loc, currencies, DEFAULT_CURRENCY);
    const currentCurrency = formatCurrencySymbol(defaultCurrency);

    return (
        <Fragment>
            <img
                className="jackpot-item-logo-label"
                src={`${imageBaseUrl}/jackpot/jackpot-${name}-label.png`}
                alt={`${name}-label`}
            />
            <div className={`jackpot-item__value`}>
                <span>{currentCurrency}</span>
                <OdometerComponent value={value} name={name} />
            </div>
        </Fragment>
    );
};

export default JackpotItemInformation;
