import JackpotItemInformation from './JackpotItemInformation';
import { JACKPOT_LABEL_GRAND, JACKPOT_LABEL_MAJOR, JACKPOT_LABEL_MIMI, JACKPOT_LABEL_MINOR } from '../../../../variables';
import { imageBaseUrl } from '../../../../services/http';

const availableLabels = [JACKPOT_LABEL_MIMI, JACKPOT_LABEL_MINOR, JACKPOT_LABEL_MAJOR, JACKPOT_LABEL_GRAND];

const JackpotItem = ({ name, value, isBanner }) => {
    if (!availableLabels.includes(name)) return null;

    if (isBanner) {
        return (
            <div className={`jackpot-item jackpot-banner-item jackpot-item-${name}`} data-testid={`jackpot-item-${name}`}>
                <JackpotItemInformation value={value} name={name} />
            </div>
        );
    }

    return (
        <section className={`jackpot-item jackpot-item-${name}`} data-testid={`jackpot-item-${name}`}>
            <img
                className="jackpot-item-logo"
                src={`${imageBaseUrl}/jackpot/jackpot-${name}-logo.png`}
                alt={`${name}-logo`}
            />
            <JackpotItemInformation value={value} name={name} />
        </section>
    );
};

export default JackpotItem;
