import { Fragment } from 'react';
import useOdometer from '../../../hooks/useOdometer';
import 'odometer/themes/odometer-theme-default.css';

const OdometerComponent = ({ value }) => {
    const { Odometer, odometerValue } = useOdometer(value);
    const [main, digit] = odometerValue.toFixed(2).split('.');

    return (
        <Fragment>
            <Odometer value={Number(main)} format="d" theme="default" />
            {digit?.length && (
                <Fragment>
                    <span>.</span>
                    <Odometer value={Number(digit[0] || 0)} format="d" theme="default" />
                    <Odometer value={Number(digit[1] || 0)} format="d" theme="default" />
                </Fragment>
            )}
        </Fragment>
    );
};

export default OdometerComponent;
