import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Link from 'next/link';
import JackpotList from './JackpotList';
import Lamp from '../../../svg-icons/Lamp';
import { imageBaseUrl } from '../../../../services/http';
import routes from '../../../../data/routes.json';

const JackpotBanner = ({ extraClass = '' }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);

    return (
        <div className={`jackpot-banner-wrapper ${extraClass}`} data-testid="jackpot-banner-wrapper">
            <img className="jackpot-banner-logo" src={`${imageBaseUrl}/jackpot/jackpot_logo.png`} alt="JackpotLogo" />
            {!isMobile && <Lamp />}
            <JackpotList isBanner />
            <div className='jackpot-banner-redirect-container'>
                {isMobile && <Lamp />}
                <Link href={routes.jackpot} as={paths[routes.jackpot]}>
                    <a className="btn btn-submit btn-modern">{tr['btn.discover']}</a>
                </Link>
            </div>
        </div>
    );
};

export default JackpotBanner;
