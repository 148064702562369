import cx from 'classnames';
import { imageBaseUrl } from '../../../../services/http';

const JackpotFailed = ({ tr, isAltStyle = false, isLobby = false }) => {
    return (
        <div
            className={cx('jackpot-failed-wrapper', {
                'alt-color': isAltStyle
            })}
        >
            {!isLobby && <img src={`${imageBaseUrl}/error_animation.png`} alt="Failes to load source" />}
            <p
                className={cx('jackpot-failed', {
                    'jackpot-failed-lobby': isLobby
                })}
            >
                {tr['jackpot.failed']}
            </p>
        </div>
    );
};

export default JackpotFailed;
