import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getCurrencyByLocation from '@tlf-e/brand-utils/dist/helpers/getCurrencyByLocation';
import cx from 'classnames';
import JackpotItem from './JackpotItem';
import FormLoader from '../../FormLoader';
import { getWazdanInfoData } from '../../../../store/reducers/jackpot';
import { DEFAULT_CURRENCY, JACKPOT_WAZDAN_PARAM } from '../../../../variables';

const JackpotList = ({ isBanner = false }) => {
    const { counters } = useSelector((state) => state.jackpot.data);
    const { isCountersLoading } = useSelector((state) => state.jackpot);
    const { information } = useSelector((state) => state.user);
    const { loc } = useSelector((state) => state.global.clientIp);
    const { currencies } = useSelector((state) => state.global.data);
    const dispatch = useDispatch();
    const defaultCurrency = information.currency || getCurrencyByLocation(loc, currencies, DEFAULT_CURRENCY);

    useEffect(() => {
        const fetchWazdanInfo = () => dispatch(
            getWazdanInfoData({
                promotionIds: [JACKPOT_WAZDAN_PARAM],
                currency: defaultCurrency
            })
        );

        fetchWazdanInfo();

        const intervalId = setInterval(fetchWazdanInfo, 30000);

        return () => clearInterval(intervalId);
    }, []);

    if (isCountersLoading) return <FormLoader />;

    return (
        <div className={cx('jackpot-list', { 'jackpot-banner-list': isBanner })} data-testid="jackpot_list">
            {counters.map((item) => (
                <JackpotItem isBanner={isBanner} key={item.name} name={item.name} value={item.value} />
            ))}
        </div>
    );
};

export default JackpotList;
