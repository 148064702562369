import { useState, useEffect } from 'react';

const useOdometer = (targetValue) => {
    const [odometerValue, setOdometerValue] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [Odometer, setOdometer] = useState(() => () => null);

    useEffect(() => {
        if (!isLoaded) {
            import('react-odometerjs').then((module) => {
                setIsLoaded(true);
                setOdometer(() => module.default);
            }).catch(() => {
                setIsLoaded(false);
            });
        }
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            setOdometerValue(targetValue);
        }
    }, [isLoaded, targetValue]);

    return { Odometer, odometerValue };
};

export default useOdometer;
