import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import reduceGameIds from '@tlf-e/brand-utils/dist/games/gamesCommon/reduceGameIds';

const useJackpotGames = () => {
    const { gamesIds } = useSelector((state) => state.jackpot.data);
    const { isGamesLoading, claimWazdanFailed } = useSelector((state) => state.jackpot);
    const { games } = useSelector((state) => state.games.data);
    const jackpotGames = useMemo(() => reduceGameIds(gamesIds, games), [games, gamesIds]);

    return { jackpotGames, isGamesLoading, claimWazdanFailed };
};

export default useJackpotGames;
